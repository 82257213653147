@import "colors";

body,
html {
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  color: white;
}

.feature-row {
  padding-bottom: 48px;
}

.feature-row svg {
  max-width: 10rem;
  max-height: 10rem;
}

.featurette svg {
  max-width: 20rem;
  max-height: 20rem;
}

.marketing {
  position: relative;
  padding-top: 4rem;
  z-index: 100;
}

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.featurette {
  padding-top: 48px;
  padding-bottom: 48px;
}

.featurette-divider {
  margin: 5rem 0;
}

.featurette-heading {
  letter-spacing: -0.05rem;
}

.hidden-iframe {
  height: 0px !important;
  width: 0px !important;
}

@media (min-width: 40em) {
  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

@import "colors";
@import "../../node_modules/bootstrap/scss/bootstrap";

.card-content {
  margin-top: 8px;
}

.card-content ul {
  padding-left: 0px;
}

.card-content li {
  list-style: none;
  padding-top: 8px;
}

.pricing {
  padding-top: 140px;
  padding-bottom: 140px;
  text-align: center;
}

.pricing .card {
  display: inline-block;
  max-width: 360px;
  padding: 48px 36px;
  border-radius: 12px;
  margin: 16px;
  border: none;
}

.pricing .card-title {
  font-weight: 500;
  padding: 16px 0px 16px 0px;
  margin: 0px;
}

.pricing .card-header {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.pricing .card-body {
  padding: 0px;
}

.pricing .card-point {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-right: 8px;
}

.pricing .card-point.positive {
  background-color: $primary;
  color: white;
}

.pricing .card-point.negative {
  background-color: $gray-200;
  color: $primary;
}

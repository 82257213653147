@import "colors";
@import "../../node_modules/bootstrap/scss/bootstrap";

.header-background {
  background-image: linear-gradient(45deg, $gradientStart, $gradientEnd);
  height: 100vh;
  width: 100vw;
}

.shape {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}

#shape1 {
  position: absolute;
  top: 50px;
  left: -150px;
  width: 300px;
  height: 300px;
}

#shape2 {
  position: absolute;
  bottom: -150px;
  left: -150px;
  width: 300px;
  height: 300px;
}

#shape3 {
  position: absolute;
  width: 300px;
  height: 150px;
  right: 0;
  top: 25%;
  transform: translate(25%, 75%) rotate(-90deg);
  border-radius: 0;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border-bottom: 0;
  animation: shapeanim 2s;
  -moz-animation: shapeanim 2s;
  -webkit-animation: shapeanim 2s;
}

@keyframes shapeanim {
  from {
    transform: translate(25%, 50%) rotate(-90deg);
  }
  to {
    transform: translate(25%, 75%) rotate(-90deg);
  }
}

.header {
  height: 100vh;
}

.header-row {
  height: 100vh;
}

.header .subtitle {
  color: #e9ecef;
}

.header-content {
  position: inline;
  width: 100%;
  max-width: 460px;
}

.header-content .btn {
  width: 100%;
}

.header-content .btn:hover {
  background-color: $gray-400 !important;
}

.header-column {
  justify-content: center;
}

.header-screenshot-wrapper {
  position: relative;
  width: 100%;
  margin-top: 0px;
  height: 100%;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.header-screenshot-first {
  position: absolute;
  bottom: 0;
  top: 10%;
  left: 0;
  z-index: 4;
  transform: perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg);
  transition: 0.6s ease all;
}

.header-screenshot-second {
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: 2;
  transform: perspective(800px) rotateY(-25deg) scale(0.9) rotateX(10deg);
  transition: 0.6s ease all;
}

.header-screenshot-second img {
  width: 350px;
}

@media (max-width: 1200px) {
  .header-screenshot-second {
    left: 20%;
  }
}

@media (max-width: 992px) {
  .header-row {
    display: block !important;
  }
  .header-column {
    display: block !important;
    height: 50%;
  }
  .header-screenshot-second {
    top: 0px;
    left: 30%;
  }
  .header-screenshot-wrapper {
    left: 50%;
    transform: translate(-50%, 0);
    overflow: visible;
  }
  .header-column:first-child {
    height: 380px;
    align-items: end !important;
    padding-bottom: 50px;
  }
  .header-screenshot-second img {
    width: 250px;
  }
  .header-background {
    height: 980px;
  }
  .header {
    height: 980px;
  }
  .header-row {
    height: 980px;
  }
}

@media (max-width: 768px) {
  .header-screenshot-wrapper {
    left: 40%;
  }
}

@media (max-width: 375px) {
  .header-screenshot-second img {
    width: 200px;
  }
}

//@media (min-width: 992px) {
//  .header-screenshot-wrapper {
//margin-top: 200px;
//height: calc(100% - 200px);
//  }
//}

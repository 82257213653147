.blog-title {
  padding-top: 140px;
  padding-bottom: 32px;
}

.blog {
  margin-top: 20px;
  background-color: #f0f5f9;
  min-height: 360px;
}

.blog-list {
  padding-top: 64px;
  padding-bottom: 128px;
}

.blog-item {
  padding: 16px;
  cursor: pointer;
  margin-bottom: 32px;
}

.blog-item img {
  max-height: 138px;
  object-fit: cover;
}

.blog-item:hover {
  background-color: #f8f9fa;
}

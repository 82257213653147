@import "colors";
@import "../../node_modules/bootstrap/scss/bootstrap";

.footer {
  padding-top: 60px;
}

.footer ul {
  padding-left: 0px;
}

.footer li {
  list-style: none;
  padding-top: 8px;
}

.footer ul li:first-child {
  font-weight: bold;
}

.footer ul li:nth-child(n + 2) a {
  color: $gray-200;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.footer .content {
  padding-bottom: 60px;
}

.footer .sns {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer .sns .twitter:hover {
  color: #1da1f2 !important;
}

.footer .brand-subtitle {
  color: $gray-200;
}

.navbar {
  height: 5em;
  z-index: 9000;
}

.navbar a,
.offcanvas a {
  padding-left: 32px !important;
  padding-right: 32px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-decoration: none;
  color: black;
  display: inline-block;
}

.navbar .btn,
.offcanvas .btn {
  padding-left: 32px !important;
  padding-right: 32px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

@import "colors";
@import "../../node_modules/bootstrap/scss/bootstrap";

.download {
  padding-top: 140px;
  padding-bottom: 140px;
}

.download .card {
  display: block;
  max-width: 920px;
  background-image: linear-gradient(45deg, $gradientStart, $gradientEnd);
  border: none;
  padding: 60px 0px 60px 0px;
  margin: 100px auto;
}

.download .card .title {
  padding-bottom: 1.5em;
}

.download .card .subtitle {
  padding-bottom: 1.5em;
}

.download .card .card-title {
  padding-bottom: 0.5em;
}

.download .card .card-subtitle {
  padding-bottom: 0.5em;
}

.download .card .btn {
  width: 240px;
  height: 60px;
}

.download .card .btn > * {
  padding-top: 10px;
}

.download .card .btn:first-child {
  margin-right: 8px;
}

@media (max-width: 540px) {
  .download .card .btn:first-child {
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

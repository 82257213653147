.faq {
  padding-top: 140px;
  padding-bottom: 140px;
  max-width: 778px !important;
}

.faq .accordion-button {
  font-size: 20px;
  font-weight: bold;
  padding-top: 28px;
  padding-bottom: 28px;
}
